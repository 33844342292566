<template>
  <div class="s-item">
    <div class="s-item-info">
      <div class="s-space-y-2 s-mr-auto">
        <p class="s-text-gray-darker s-font-semibold">發票編號：{{ invoiceLog.no }}</p>
        <p class="s-text-gray s-text-xs s-whitespace-nowrap">開立時間：{{ moment(invoiceLog.issued_at).format('YYYY-MM-DD') }}</p>
        <p class="s-text-gray s-text-xs s-whitespace-nowrap">上傳時間：{{ moment(invoiceLog.created_at).format('YYYY-MM-DD') }}</p>
      </div>
      <div class="s-flex s-flex-col s-items-end">
        <p>
          <span class="s-tag">{{ statusMapping[invoiceLog.status] }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as consts from '@/consts'

export default {
  props: {
    invoiceLog: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusMapping: {
        [consts.INVOICE_LOG_STATUS.INIT]: '等待處理',
        [consts.INVOICE_LOG_STATUS.E_VERIFY]: '發票驗證中',
        [consts.INVOICE_LOG_STATUS.E_VALID]: '發票已處理',
        [consts.INVOICE_LOG_STATUS.E_INVALID]: '發票不存在或已作廢',
        [consts.INVOICE_LOG_STATUS.T_VERIFY]: '發票審核中 ',
        [consts.INVOICE_LOG_STATUS.T_VALID]: '發票已審核',
        [consts.INVOICE_LOG_STATUS.T_INVALID]: '發票不合格',
      },
    }
  },
  computed: {
    moment () {
      return moment
    },
  },
}
</script>

<style scoped>
.s-item {
  display: flex;
  align-items: center;
  background-color: var(--s-white);
}

.s-item-info {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  padding: 1rem;
}
</style>
