<template>
  <div class="s-loading">
    <div class="lds-ring">
      <p v-if="loadingMessage">{{ loadingMessage }}</p>
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loadingMessage: {
      type: String,
      default: "",
    },
  },
}
</script>

<style scoped>
  .s-layout *:after {
    --s-gray: #979797 !important;
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #8ba2b5;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #8ba2b5 transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .s-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(155, 155, 155, 0.2);
    z-index: 1000;
  }
</style>
