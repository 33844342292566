import https from "./https";
import store from "@/store";

const invoice = {
  async updateOrCreateInvoiceLog(params) {
    const { data } = await https.put(`${store.state.liffGeneral.orgCode}/liff/einvoice/invoice-log`, params)
    return data
  },

  async detectQRCode(image) {
    let formData = new FormData();
    formData.append("image", image);
    const { data } = await https.post(`${store.state.liffGeneral.orgCode}/liff/einvoice/detect-qr-code`, formData)
    return data
  },

  async queryByCarrierInfo(params) {
    const { data } = await https.put(`${store.state.liffGeneral.orgCode}/liff/einvoice/query-invoices-by-carrier`, params)
    return data
  },

  async storeCarrierInfo(params) {
    const { data } = await https.put(`${store.state.liffGeneral.orgCode}/liff/einvoice/carrier-info`, params)
    return data
  },

  async getCarrierInfo() {
    const { data } = await https.get(`${store.state.liffGeneral.orgCode}/liff/einvoice/carrier-info`)
    return data
  },

  async getInvoiceLogs(params) {
    const { data } = await https.get(`${store.state.liffGeneral.orgCode}/liff/einvoice/invoice-logs`, { params })
    return data
  },
}

export default invoice
